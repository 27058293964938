import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div``;

const MenuContainer = styled.div`
    display: none;
    ${mobile({
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        background: "white",
        color: "black",
    })}
`;


const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Logo = styled.img`
    width: 80%;
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
`;

const Center = styled.div`
    font-size: 1.5rem;
    cursor: pointer;
    font-family: FuturaPT-Bold;
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
`;

const MobileNavbar = () => {
    return (
        <>
            <Container>
                {" "}
                <MenuContainer>
                    {" "}
                    <Left>
                    </Left>
                    <Center>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/"
                        >
                            <LogoContainer>
                            <Logo
                                src={
                                    "https://ik.imagekit.io/dnddecpho/Haofa/favicon_FRYwaCRKU.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665372300464"
                                }
                            ></Logo>
                            </LogoContainer>
                        </Link>
                    </Center>
                    <Right>
                    </Right>
                </MenuContainer>
            </Container>
        </>
    );
};

export default MobileNavbar;
