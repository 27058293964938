import { Send } from "@material-ui/icons";
import styled from "styled-components";
import { mobile } from "../responsive";
import { useState } from "react";
import { addNewsletter } from "../redux/apiCalls";
import { ReactPixel } from "react-facebook-pixel";

const Container = styled.div`
    height: 35vh;
    background-color: #ac997b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${mobile({ padding: "0 2rem 0 2rem", height: "35vh" })}
`;
const Title = styled.h1`
    font-family: FuturaPT-Bold;
    letter-spacing: 0.3rem;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    ${mobile({ fontSize: "1.2rem", margin: "0px" })}
`;

const Desc = styled.div`
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 20px;
    text-align: center;
    ${mobile({ textAlign: "center", fontSize: "1rem", margin: "20px 0" })}
`;

const InputContainer = styled.div`
    width: 50%;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    ${mobile({ width: "80%", height: "30px" })}
`;

const Input = styled.input`
    border: none;
    flex: 8;
    padding-left: 20px;
    ${mobile({ fontSize: "0.8rem" })}
`;

const Button = styled.button`
    flex: 1;
    border: none;
    background-color: teal;
    color: white;
`;

const Newsletter = () => {
    const [email, setEmail] = useState("");

    const handleAddNewsletter = () => {
        addNewsletter({ email });
        const load = async () => {
            try {
                ReactPixel.init(532645418897855, null, {
                    autoConfig: true,
                    debug: true,
                });
                ReactPixel.track("Subscribe", { value: email });
            } catch (err) {
                console.log(err);
            }
        };
        load();
    };

    return (
        <Container>
            <Title>SUBSCRIBE NEWSLETTER</Title>
            <Desc>Get timely updates for your favorite products</Desc>
            <InputContainer>
                <Input
                    placeholder="Your email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button onClick={handleAddNewsletter}>
                    <Send />
                </Button>
            </InputContainer>
        </Container>
    );
};

export default Newsletter;
