import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import { Link } from "react-router-dom";
import MobileNavbar from "./MobileNavbar";

const Container = styled.div`
    height: 80px;
    ${mobile({ height: "inherit" })}
`;

const Wrapper = styled.div`
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    ${mobile({ padding: "10px 0px", display: "none" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
`;

const Center = styled.div`
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    max-height: 50px;
    ${mobile({ maxHeight: "4vh", marginLeft: "5vh" })}
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${mobile({ flex: 2, justifyContent: "center" })}
`;

const ScrollContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
height: 40px;
border: none;
background-color: #1f2f4d;
color: white;
overflow: hidden;
`;

const ScrollText = styled.div`
text-align: center;
display: block;
${mobile({ display: "none" })}
/* animation properties */
-moz-transform: translateX(-100%);
-webkit-transform: translateX(-100%);
transform: translateX(-100%);

-moz-animation: my-animation 15s linear infinite;
-webkit-animation: my-animation 15s linear infinite;
animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
from { -moz-transform: translateX(-100%); }
to { -moz-transform: translateX(100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
from { -webkit-transform: translateX(-100%); }
to { -webkit-transform: translateX(100%); }
}

@keyframes my-animation {
from {
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
to {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}


`;

const MobileScrollText = styled.div`
text-align: center;
display: none;
${mobile({ display: "block" })}
/* animation properties */
-moz-transform: translateX(-100%);
-webkit-transform: translateX(-100%);
transform: translateX(-100%);

-moz-animation: my-animation 15s linear infinite;
-webkit-animation: my-animation 15s linear infinite;
animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
from { -moz-transform: translateX(-100%); }
to { -moz-transform: translateX(100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
from { -webkit-transform: translateX(-100%); }
to { -webkit-transform: translateX(100%); }
}

@keyframes my-animation {
from {
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
to {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}


`;

const Navbar = () => {

    return (
        <>
            <Container>
                <Wrapper>
                    {" "}
                    <Left>
                    </Left>
                    <Center>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/"
                        >
                            <Logo
                                src={
                                    "https://ik.imagekit.io/dnddecpho/Haofa/favicon_FRYwaCRKU.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665372300464"
                                }
                            ></Logo>
                        </Link>
                    </Center>
                    <Right>
                    </Right>
                </Wrapper>
                <MobileNavbar></MobileNavbar>
            </Container>
            <ScrollContainer>
                <ScrollText>HAOFA INTERNATIONAL WARRANTY CHECKER - Check your warranty details with serial code.</ScrollText>
                <MobileScrollText>HAOFA International Warranty Checker</MobileScrollText>
                
            </ScrollContainer>
        </>
    );
};

export default Navbar;
