import { publicRequest } from "../requestMethods";

export const addNewsletter = async (email) => {
    try {
        const res = await publicRequest.post("/newsletter", email);
        if (res.data) {
            alert("You have subscribed to our newsletter successfully.");
        }
    } catch (err) {
        alert("Email already exists.");
    }
};
