import Warranty from "./pages/Warranty";
import styled from "styled-components";

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { useEffect } from "react";
import TagManager from "react-gtm-module";

const GlobalContainer = styled.div`
    font-family: "FuturaPT-Book";
`;


const tagManagerArgs = {
    gtmId: "G-WWPJVH8YGH",
};

TagManager.initialize(tagManagerArgs);

const App = () => {
    useEffect(() => {
        window.dataLayer.push({
            event: "pageview",
            eventProps: {
                category: "home",
                action: "pageview",
                label: "initialized",
                value: 1
            }
        });
    });

    return (
        <GlobalContainer>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Warranty />
                    </Route>
                </Switch>
            </Router>
        </GlobalContainer>
    );
};

export default App;
